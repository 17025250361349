import React from "react";
import "./VolunteerCards.css";

const VolunteerCard = ({ title, content, ...props }) => (
  <div className="volunteer-card">
    <h3>{title}</h3>
    <p>{content}</p>

    {Object.entries(props).map(([key, value]) => (
      <p key={key} className={`volunteer-card-${key}`}>
        {value}
      </p>
    ))}
  </div>
);

const VolunteerCards = ({ cards }) => {
  return (
    <div className="volunteer-cards-container">
      {cards.map((card, index) => (
        <VolunteerCard key={index} {...card} />
      ))}
    </div>
  );
};

export default VolunteerCards;
