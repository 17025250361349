import React, { lazy } from "react";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import wire from "../../assets/images/wire-6.png";
import AnimatedBackground from "../../components/AnimatedBackground";
import "./contact.css";
import location from "../../assets/images/location.png";
import envelope from "../../assets/images/email.png";
import phone from "../../assets/images/call-us.png";
import ContactForm from "../../components/forms/contactForm";

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

const ContactUs = () => {
  return (
    <>
      <section className="pageHeaderSection pageSection bkgWhite">
        <AnimatedBackground
          backgroundImage={wire}
          className="imageWire pageHeaderWire2"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)} className="colorBrown">
                    Contact Us
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorBrown">
                    For more information about our services, To get invoved, or
                    to make a donation
                  </article>
                </motion.div>
              </motion.div>
            </div>

            <div className="ver imageFadeWrapper">
              {/* <ProgressiveImage
                lowQualitySrc={volunteerContent.heroImg.lowQualityImage}
                highQualitySrc={volunteerContent.heroImg}
                alt={volunteerContent.heroTitle}
                className={"fadeImage faded"}
              /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="contactSection">
        <div className="container">
          <div className="itemWrapper">
            <div className="itemLeft">
              <div className="header">
                <h3 className="colorBrown">Get in touch</h3>
                <article className="colorBrown">
                  Together, we can create a world where every woman lives free
                  from violence and abuse
                </article>
              </div>
              <div className="itemInfo">
                <div className="hor">
                  <div className="ver">
                    <div className="iconItem">
                      <img src={location} className="icon" alt="" />
                    </div>
                  </div>
                  <div className="ver flexMore">
                    <div className="detailItem colorBrown">
                      <h3>Head Office</h3>
                      <article>Lorem ipsum dolor sit amet.</article>
                    </div>
                  </div>
                </div>

                <div className="hor">
                  <div className="ver">
                    <div className="iconItem">
                      <img src={envelope} className="icon" alt="" />
                    </div>
                  </div>
                  <div className="ver flexMore">
                    <div className="detailItem colorBrown">
                      <h3>Email Us</h3>
                      <article>contact@rescuewomeninternational.org</article>
                    </div>
                  </div>
                </div>

                <div className="hor">
                  <div className="ver">
                    <div className="iconItem">
                      <img src={phone} className="icon" alt="" />
                    </div>
                  </div>
                  <div className="ver flexMore">
                    <div className="detailItem colorBrown">
                      <h3>Call Us</h3>
                      <article>(123) 456-7890</article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="itemRight">
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4084402.321492104!2d29.660454625591825!3d1.372202781895549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1771a69f6499f945%3A0x874155ce43014549!2sUganda!5e0!3m2!1sen!2sgh!4v1725250704504!5m2!1sen!2sgh"
                  width="100%"
                  height="600"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactFormSection">
        <div className="container">
          <ContactForm />
        </div>
      </section>
    </>
  );
};

export default ContactUs;
