import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import PropTypes from "prop-types";
import "./animated-background.css";

const AnimatedBackground = ({ backgroundImage, className }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.1 });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isInView]);

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 0.8,
        ease: "easeIn",
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    ></motion.div>
  );
};

AnimatedBackground.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default AnimatedBackground;
