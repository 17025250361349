import React from "react";
import { Helmet } from "react-helmet";
import { whatWeDoContent } from "../../constants/data";

const WhatWeDoPageMetadata = () => {
  const baseUrl = "https://rescuewomeninternational.org";
  const whatWeDoUrl = `${baseUrl}/what-we-do`;

  const metadata = {
    title: `${whatWeDoContent.heroTitle} | Rescue Women International`,
    description: whatWeDoContent.heroContent,
    keywords:
      "domestic abuse support, emergency shelter, counseling, legal advocacy, financial assistance, job training, community education",
    og: {
      title: `${whatWeDoContent.heroTitle} - Rescue Women International`,
      description: `Discover our comprehensive support services: ${whatWeDoContent.serviceCards
        .map((card) => card.title)
        .join(", ")}`,
      image: `${baseUrl}/logo.png`,
      url: whatWeDoUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: `${whatWeDoContent.heroTitle} - Rescue Women International`,
      description: whatWeDoContent.heroContent,
      image: `${baseUrl}/logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "NGO",
      name: "Rescue Women International",
      url: whatWeDoUrl,
      logo: `${baseUrl}/logo.png`,
      description: whatWeDoContent.heroContent,
      serviceType: whatWeDoContent.serviceCards.map((card) => card.title),
      areaServed: {
        "@type": "Country",
        name: "Ghana", // Replace with actual area served
      },
      knowsAbout: [
        "Domestic Violence",
        "Women's Rights",
        "Crisis Intervention",
        "Counseling",
        "Legal Advocacy",
        "Financial Assistance",
        "Job Training",
        "Community Education",
      ],
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default WhatWeDoPageMetadata;
