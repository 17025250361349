import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { eventsContent } from "../../constants/data";
import "./eventRegistration.css";

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const EventRegistration = () => {
  const { eventSlug } = useParams();
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const selectedEvent = eventsContent.eventCards.find(
      (event) => convertToSlug(event.title) === eventSlug
    );
    setEvent(selectedEvent);
  }, [eventSlug]);

  if (!event) {
    return <div>Loading...</div>;
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().matches(
      /^[0-9]{10}$/,
      "Phone number must be 10 digits"
    ),
    specialRequirements: Yup.string(),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // Here you would typically send the form data to your backend
    console.log(values);
    setTimeout(() => {
      alert("Registration successful!");
      setSubmitting(false);
      resetForm();
      navigate("/events"); // Redirect to events page after successful submission
    }, 1000);
  };

  return (
    <div className="eventRegistrationPage">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="registrationContent"
        >
          <h1 className="eventTitle">Register for {event.title}</h1>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              specialRequirements: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="registrationForm">
                <div className="formGroup">
                  <label htmlFor="firstName">First Name</label>
                  <Field type="text" name="firstName" />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="errorMessage"
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="lastName">Last Name</label>
                  <Field type="text" name="lastName" />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="errorMessage"
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="errorMessage"
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="phone">Phone Number</label>
                  <Field type="tel" name="phone" />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="errorMessage"
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="specialRequirements">
                    Special Requirements
                  </label>
                  <Field as="textarea" name="specialRequirements" />
                  <ErrorMessage
                    name="specialRequirements"
                    component="div"
                    className="errorMessage"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btnPrimary bkgYellow"
                >
                  {isSubmitting ? "Submitting..." : "Register"}
                </button>
              </Form>
            )}
          </Formik>
        </motion.div>
      </div>
    </div>
  );
};

export default EventRegistration;
