import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    // Check if there's a hash in the URL or if it's a POP navigation
    if (hash || navigationType === "POP") {
      return;
    }

    // If there's no hash and it's not a POP navigation, scroll to top
    window.scrollTo(0, 0);
  }, [pathname, hash, navigationType]);

  return null;
}
