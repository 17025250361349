import { createBrowserRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import Home from "../pages/home/index";
import NotFoundPage from "../pages/not_found";
import EventDetailLayout from "../layouts/EventDetailLayout";
import EventDetail from "../pages/event-detail";
import PrivacyPolicy from "../pages/privacy-policy";
import CookiePolicy from "../pages/cookie-policy";
import TermsAndConditions from "../pages/terms-and-conditions";
import ScrollToTop from "../components/ScrollToTop";
import AboutUs from "../pages/about-us";
import WhatWeDo from "../pages/what-we-do";
import ContactUs from "../pages/contact-us";
import Volunteer from "../pages/volunteer";
import Events from "../pages/events";
import EventRegistration from "../pages/register";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <Layout />
      </>
    ),
    children: [
      { index: true, element: <Home /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "what-we-do", element: <WhatWeDo /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "volunteer", element: <Volunteer /> },
      // { path: "donate", element: <Donate /> },
      // { path: "donation-success", element: <DonationSuccess /> },
      {
        path: "events",
        element: <EventDetailLayout />,
        children: [
          { index: true, element: <Events /> },
          { path: "past-events/:eventSlug", element: <EventDetail /> },
          {
            path: "upcoming-events/:eventSlug",
            element: <EventDetail />,
          },
          {
            path: "/events/register/:eventSlug",
            element: <EventRegistration />,
          },
        ],
      },
      { path: "*", element: <NotFoundPage /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "cookie-policy", element: <CookiePolicy /> },
      { path: "terms-and-conditions", element: <TermsAndConditions /> },
    ],
  },
]);

export default router;
