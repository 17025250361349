import React, { lazy } from "react";
import "./home.css";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import wire4 from "../../assets/images/wire-4.png";
import wire3 from "../../assets/images/Group-37.png";
import wire2 from "../../assets/images/wire-2.png";
import wire1 from "../../assets/images/wire-1.png";
import wire5 from "../../assets/images/wire-3.png";
import { homeContent } from "../../constants/data";
import { Link } from "react-router-dom";
import AnimatedBackground from "../../components/AnimatedBackground";
import TestimonySlider from "../../components/TestimonySlider";
import HomePageMetadata from "../../components/generateMetadata/HomePageMetadata";
const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

const Home = () => {
  return (
    <>
      <HomePageMetadata />
      <section className="showcase bkgMauve">
        <AnimatedBackground
          backgroundImage={wire4}
          className="imageWire showcaseWire"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)}>
                    {homeContent.heroTitle}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article>{homeContent.heroContent}</article>
                </motion.div>

                <motion.div variants={textVariant(1.3)} className="btnWrapper">
                  <Link to={"/what-we-do"} className="btnPrimary">
                    get started
                  </Link>
                </motion.div>
              </motion.div>
            </div>
            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={homeContent.heroImg.lowQualityImage}
                highQualitySrc={homeContent.heroImg}
                alt={homeContent.heroTitle}
                className={"fadeImage"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="theSilentEpidemicSection pageSection">
        <AnimatedBackground
          backgroundImage={wire3}
          className="imageWire theSilentEpidemicWire"
        />
        <div className="container">
          <div className="hor">
            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={
                  homeContent.theSilentEpidemicSection.image.lowQualityImage
                }
                highQualitySrc={homeContent.theSilentEpidemicSection.image}
                alt={homeContent.theSilentEpidemicSection.header}
                className={"fadeImage"}
              />
            </div>
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)}>
                    {homeContent.theSilentEpidemicSection.header}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article>
                    {homeContent.theSilentEpidemicSection.textContent}
                  </article>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="affectsYouSection pageSection">
        <AnimatedBackground
          backgroundImage={wire2}
          className="imageWire affectsYouWire"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)}>
                    {homeContent.affectsYouSection.header}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article>{homeContent.affectsYouSection.textContent}</article>
                </motion.div>
              </motion.div>
            </div>

            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={
                  homeContent.affectsYouSection.image.lowQualityImage
                }
                highQualitySrc={homeContent.affectsYouSection.image}
                alt={homeContent.affectsYouSection.header}
                className={"fadeImage"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="homeAboutSection pageSection">
        <AnimatedBackground
          backgroundImage={wire1}
          className="imageWire homeAboutSectionWire"
        />
        <div className="container">
          <div className="hor">
            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={homeContent.aboutSection.image.lowQualityImage}
                highQualitySrc={homeContent.aboutSection.image}
                alt={homeContent.aboutSection.header}
                className={"fadeImage"}
              />
            </div>
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)}>
                    {homeContent.aboutSection.header}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article>{homeContent.aboutSection.textContent}</article>
                </motion.div>
                <motion.div variants={textVariant(1.3)} className="btnWrapper">
                  <Link to={"/about-us"} className="btnPrimary bkgMauve">
                    about us
                  </Link>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="homeServiceSection pageSection">
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader colorWhite">
                  <motion.h1 variants={textVariant(1.1)}>
                    {homeContent.serviceSection.header}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorWhite">
                    {homeContent.serviceSection.textContent}
                  </article>
                </motion.div>
                <motion.div variants={textVariant(1.3)} className="btnWrapper">
                  <Link
                    to={"/about-us"}
                    className="btnPrimary bkgLight colorGrey"
                  >
                    our service
                  </Link>
                </motion.div>
              </motion.div>
            </div>
            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={homeContent.serviceSection.image.lowQualityImage}
                highQualitySrc={homeContent.serviceSection.image}
                alt={homeContent.serviceSection.header}
                className={"fadeImage"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="survivorStoriesSection pageSection">
        <AnimatedBackground
          backgroundImage={wire5}
          className="imageWire survivorStoriesWire"
        />
        <div className="container">
          <div className="hor">
            <div className="ver"></div>
            <div className="ver stackTop">
              <TestimonySlider />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
