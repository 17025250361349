import React from "react";

import "./policy.css";
import PrivacyPolicyContent from "../../constants/PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyPolicyContent />
    </>
  );
};

export default PrivacyPolicy;
