import showcaseImage from "../assets/images/woman-embrace.png";
import silentImage from "../assets/images/woman-depressed.png";
import affectImage from "../assets/images/guy-desk-coloured.png";
import homeAboutImage from "../assets/images/women-comforting-2.png";
import homeServiceImage from "../assets/images/logo-and-therapy.png";
import quote from "../assets/images/quotation.png";
import aboutHeroImg from "../assets/images/womenhuge-img-1.png";
import aboutPageSecImg from "../assets/images/stretch-hands-1.png";
import missionImg1 from "../assets/images/sleeping-beauty.png";
import missionImg2 from "../assets/images/2150248253.png";
import impactImg1 from "../assets/images/Unity-hands.png";
import impactImg2 from "../assets/images/crying-mama.png";
import advocateImg1 from "../assets/images/Advocate-1.png";
import advocateImg2 from "../assets/images/Advocate-2.png";
import partnerImg1 from "../assets/images/partner1.png";
import partnerImg2 from "../assets/images/partner2.png";
import whatWeDoImg from "../assets/images/Man_reaching_out.png";
import emergencyImg from "../assets/images/ermergency-shelter-1.png";
import counselingImg from "../assets/images/counseling-support.png";
import legalImg from "../assets/images/legal-advocacy.png";
import financialImg from "../assets/images/financial-assis.png";
import jobImg from "../assets/images/job-placement.png";
import commEduImg from "../assets/images/comm-advocacy.png";
import volunteerImg from "../assets/images/team-happy-female-colleagues-chatting.png";
import helpMImg from "../assets/images/volunteer1.png";
import eventHeaderImg from "../assets/images/ladies3.png";
import empowermentWorkshopImg from "../assets/images/women-meeting-1.png";
import selfDefenseClassImg from "../assets/images/StockCake-Intense-training-session.png";
import annualFundraiserImg from "../assets/images/StockCake-Donation-Box.png";
import artAndTherapySessionImg from "../assets/images/art-1.png";
import outreachProgramImg from "../assets/images/2148959246.png";

export const homeContent = {
  heroTitle: "We're here for you...",
  heroContent: `Together, we can end domestic violence`,
  heroImg: showcaseImage,
  theSilentEpidemicSection: {
    header: "The silent epidemic",
    textContent: `Domestic abuse is a silent epidemic that affects millions of people across all walks of life. It's not just about physical violence-it's about control, manipulation, and fear. It's about the scars you can't see, the wounds that don't heal even with time. Domestic abuse doesn't discriminate; it reaches into every corner of society, regardless of age, gender, race, or socioeconomic status.`,
    image: silentImage,
  },
  affectsYouSection: {
    header: "It affects you too!",
    textContent: `The boundaries of a home are not where domestic abuse ends. Higher medical expenses, less productivity, and a burdened social services system are the results. Witnessing abuse increases a child's risk of mental health problems, low academic performance, and involvement in the cycle of violence. More crime, social unrest, and financial losses are the consequences that communities must bear.`,
    image: affectImage,
  },
  aboutSection: {
    header: "Rescue Women International",
    textContent: `We're a Beacon of Hope for women seeking escape from the shadows of domestic abuse; a lighthouse, shining a ray of hope to those enduring the turbulent waters of abuse. More than refuge, we offer a transformative journey to reclaim strength and dignity through our comprehensive support programmes.`,
    image: homeAboutImage,
  },
  serviceSection: {
    header: "Your Journey, Our Commitment",
    textContent: `At Rescue Women International, we understand that every journey to safety and empowerment is unique. Our comprehensive suite of services is tailored to meet the diverse needs of survivors at every step. Discover how our carefully crafted services can be personalized to your situation, empowering you to transition from survival to thriving.`,
    image: homeServiceImage,
  },
};

export const survivorStories = {
  header: "Survivor stories",
  quoteIcon: quote,

  testimonies: [
    {
      id: "0",
      text: `My abuser was a master manipulator. He isolated me from my friends and family, convinced me that I was worthless, and made me believe that I deserved the abuse. I was terrified to leave. But Rescue Women International offered me a lifeline. They provided me with a safe place to heal, helped me understand my situation, and gave me the tools I needed to regain my self-worth. Thanks to their support, I am now able to live a life free from fear and abuse.`,
      person: "Maria",
    },
    {
      id: "1",
      text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
      person: "Diane",
    },
    {
      id: "2",
      text: " Lorem, ipsum dolor sit amet consectetur adipisicing elit.Id omnis nemo quaerat eligendi unde ab hic ducimus nihil provident, architecto, dignissimos debitis veniam labore sit quisquam natus, non facere eos. ",
      person: "Jade",
    },
  ],
};

export const aboutContent = {
  heroTitle: "Say No more, You're home",
  heroContent: `Your courage brought you to us and We're here to help you rebuild, step by step`,
  heroImg: aboutHeroImg,
  aboutSection: {
    title: "Rescue Women International",
    subTitle: "Empowering Women, Transforming Lives",
    content: `At Rescue Women International, our mission is clear: to provide immediate and comprehensive support to women escaping domestic abuse, while fostering a community where they can rebuild their lives with dignity and hope. We are a dedicated not-for-profit organization committed to ending the cycle of violence and empowering women to reclaim their strength and independence.`,
    image: aboutPageSecImg,
  },
  missionSection: {
    title: "Our Mission",
    content: `Rescue Women International is devoted to rescuing and supporting women who face domestic abuse. We believe that every woman deserves to live free from fear and violence. Our goal is to offer a sanctuary of safety, healing, and empowerment through a range of services designed to address both immediate needs and long-term recovery.`,
    image1: missionImg1,
    image2: missionImg2,
  },
  advocateSection: {
    title: "Advocate",
    content: `Raise your voice to end domestic abuse. Share our message on social media, participate in advocacy campaigns, and engage in community discussions to foster a culture of zero tolerance for violence`,
    image1: advocateImg1,
    image2: advocateImg2,
  },
  impactSection: {
    title: "Our Impact",
    content: `Since our inception, Rescue Women International has been a beacon of hope for countless women and children. Our comprehensive approach to support and empowerment has transformed lives, breaking the cycle of abuse and creating a future filled with possibility. Your support is essential to continuing this vital work and making a lasting difference in the lives of those we serve.`,
    image1: impactImg1,
    image2: impactImg2,
  },
};

export const whatWeDoContent = {
  heroTitle: "What we do",
  heroContent: `Comprehensive support for a new beginning`,
  heroImg: whatWeDoImg,
  serviceCards: [
    {
      img: emergencyImg,
      title: "emergency shelter",
      content: `Our emergency shelters offer a safe, confidential haven for women and their children fleeing abusive situations. With round-the-clock support, nutritious meals, and access to essential resources, we provide a secure environment where women can begin their journey to safety and healing`,
    },
    {
      img: counselingImg,
      title: "counseling and support service",
      content: `Understanding the emotional and psychological impact of abuse, we offer individual and group counseling sessions. Our trained professionals provide compassionate support, helping survivors navigate their trauma, build self-esteem,aand develop coping strategies.`,
    },
    {
      img: legalImg,
      title: "legal advocacy",
      content: `Navigating the legal system can be daunting for survivors of abuse. Our legal advocates offer guidance through restraining orders, custody battles, and other legal matters, ensuring that women have the support they need to assert their rights and seek justice.`,
    },
    {
      img: financialImg,
      title: "financial assistance",
      content: `Financial instability often exacerbates the challenges faced by survivors. We provide emergency financial assistance for necessities such as housing, transportation, and basic living expenses, enabling women to focus on their recovery without the added stress of financial hardship.`,
    },
    {
      img: jobImg,
      title: "job training and placement",
      content: `Empowerment through employment is a crucial step in recovery. Our job training programs offer skills development, resume building, and job placement services, helping women achieve financial independence and rebuild their lives`,
    },
    {
      img: commEduImg,
      title: "community education and advocacy",
      content: `To address domestic abuse at its roots, we engage in community education and advocacy. Through workshops, seminars, and outreach programs, we raise awareness about domestic violence, promote healthy relationships, and advocate for systemic change.`,
    },
  ],

  partnerSection: {
    title: "Partner with us",
    content: `Businesses and organizations can make a difference by partnering with Rescue Women International. Corporate sponsorships, in-kind donations, and collaborative projects help amplify our efforts and reach more women in need.`,
    image1: partnerImg1,
    image2: partnerImg2,
  },
};

export const volunteerContent = {
  heroTitle: "Join us in making a difference",
  heroContent: `Your expertise and time are priceless to our cause.`,
  heroImg: volunteerImg,

  helpMattersSection: {
    title: "Why Your Help Matters",
    content: `Domestic abuse affects millions of lives, but together we can make a difference. By volunteering with [Organization Name], you can support survivors, raise awareness, and work towards a future free from violence. Whether it’s offering a listening ear, organizing community events, or spreading awareness, every action counts.`,
    image: helpMImg,
  },
  volunteerCardsSection: {
    title: "Ways to Volunteer",
    content: `We offer multiple ways for you to contribute your time and talents, no matter your background or skills. Explore how you can get involved:`,
    volunteerCards: [
      {
        title: "Support Services",
        content: `Work directly with survivors, providing emotional support and resources.`,
      },
      {
        title: "Community Outreach",
        content: `Help educate the public about domestic abuse prevention.`,
      },
      {
        title: "Event Coordination",
        content: `Assist in organizing workshops, fundraising events, and public speaking engagements.`,
      },
      {
        title: "Administrative Support",
        content: `Lend a hand behind the scenes with office work, communication, or fundraising.`,
      },
    ],
  },
  hearOurSection: {
    title: "Hear from Our Volunteers",
    content: `Volunteering at Rescue Women International is a life-changing experience. Read about the impact it has had on our volunteers and the survivors they've supported.`,
    volunteerStatementCards: [
      {
        avatar: "",
        name: "Jane Doe",
        content: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maxime sit error expedita ullam, odit porro doloribus.`,
      },
      {
        avatar: "",
        name: "Rose Ann",
        content: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maxime sit error expedita ullam, odit porro doloribus.`,
      },
      {
        avatar: "",
        name: "Mary Griffin",
        content: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maxime sit error expedita ullam, odit porro doloribus.`,
      },
    ],
  },
};

export const eventsContent = {
  heroTitle: "Your Safe Haven",
  heroContent: `Our events are where healing begins and empowerment thrives`,
  heroImg: eventHeaderImg,

  eventCards: [
    {
      image: empowermentWorkshopImg,
      title: "Empowerment Workshop",
      subTitle: "Financial independence for survivors",
      notice: `This event is free to attend, but donations are welcome to support our work`,
      eventOverview: `Join our workshop to learn essential financial skills that help survivors of domestic abuse regain independence and control of their future.`,
      date: "September 11, 2025",
      time: "",
      location: "",
      content: `
    Our comprehensive workshop covers a range of crucial financial topics designed to empower survivors of domestic abuse:
    
    1. Budgeting Basics: Create and stick to a realistic budget.
    2. Understanding Credit: Learn about credit scores, building good credit, and repairing damaged credit.
    3. Saving Strategies: Explore saving methods and build an emergency fund.
    4. Debt Management: Gain strategies for managing and reducing debt.
    5. Banking Fundamentals: Learn about different types of bank accounts and how to keep your finances secure.
    6. Income Generation: Explore ways to increase your income, including job search strategies.
    7. Long-term Financial Planning: Understand retirement savings and investment basics.
    
    Our workshop is conducted in a safe, confidential, and supportive environment by trained professionals who understand the unique challenges faced by survivors of domestic abuse.
    
    Beyond finance, we focus on:
    - Building self-confidence
    - Setting and achieving personal goals
    - Recognizing financial red flags in relationships
    - Accessing additional resources and support services
    
    This free workshop is open to all survivors, regardless of their current financial situation. Take the first step towards financial independence and a secure future.
    
    To register or learn more, please contact [Insert Contact Information].
    
    Remember, financial independence is a crucial step towards overall independence. You have the strength to take control of your future, and we're here to support you every step of the way.
      `,
    },
    {
      image: selfDefenseClassImg,
      title: "Self-Defense Classes",
      subTitle: "Strength in safety",
      notice: `This event is free to attend, but donations are welcome to support our work`,
      eventOverview: `Self-defense class to empower women with basic techniques for protecting themselves and building physical confidence`,
      date: "September 12, 2025",
      time: "",
      location: "",
      content: `
    Our comprehensive self-defense class is designed specifically for women, focusing on essential skills for personal safety and empowerment:
    
    1. Situational Awareness: Recognize and avoid potential threats before they escalate.
    2. Verbal De-escalation: Use your voice and body language to defuse tense situations.
    3. Basic Striking Techniques: Effective use of hands, elbows, knees, and feet for self-defense.
    4. Escape Maneuvers: Break free from common holds and grabs.
    5. Ground Defense: Protect yourself on the ground and get back up quickly.
    6. Improvised Weapons: Use everyday objects for self-defense when necessary.
    7. Legal Aspects: Understand the legal implications of self-defense and your rights.
    
    Beyond physical techniques, we focus on:
    - Building self-confidence and assertiveness
    - Developing a strong mindset and overcoming fear
    - Understanding the psychology of attackers
    - Creating personal safety plans
    - Fostering a supportive community of empowered women
    
    Class Structure:
    - 8-week course, meeting once a week for 2 hours
    - Limited to 15 participants for personalized attention
    - Suitable for women of all fitness levels and ages
    - All necessary training equipment provided
    
    Our experienced instructors create a safe, supportive, and judgment-free environment. We use a trauma-informed approach to ensure all participants feel comfortable and respected throughout the learning process.
    
    Remember, self-defense is about empowering yourself, trusting your instincts, and knowing you have the tools to protect yourself if needed. Join us and take a powerful step towards personal empowerment and safety.
    
    To register or learn more, please contact [Insert Contact Information].
      `,
    },
    {
      image: annualFundraiserImg,
      title: "Annual Fundraiser",
      subTitle: "walking for freedom",
      notice: `This event is free to attend, but donations are welcome to support our work`,
      eventOverview: ``,
      date: "July 11, 2024",
      time: "",
      location: "",
      content: `
    
      `,
    },
    {
      image: artAndTherapySessionImg,
      title: "Art Therapy Session",
      subTitle: "healing through art",
      notice: `This event is free to attend, but donations are welcome to support our work`,
      eventOverview: ``,
      date: "August 11, 2024",
      time: "",
      location: "",
      content: `
    
      `,
    },
    {
      image: outreachProgramImg,
      title: "Outreach Program",
      subTitle: "You against abuse",
      notice: `This event is free to attend, but donations are welcome to support our work`,
      eventOverview: ``,
      date: "September 11, 2024",
      time: "",
      location: "",
      content: `
    
      `,
    },
  ],
};
