import React from "react";
import CookiePolicyContent from "../../constants/CookiePolicyContent";

const CookiePolicy = () => {
  return (
    <>
      <CookiePolicyContent />
    </>
  );
};

export default CookiePolicy;
