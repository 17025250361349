import React from "react";
import { Helmet } from "react-helmet";
import { volunteerContent } from "../../constants/data";

const VolunteerPageMetadata = () => {
  const baseUrl = "https://rescuewomeninternational.org";
  const volunteerUrl = `${baseUrl}/volunteer`;

  const metadata = {
    title: `${volunteerContent.heroTitle} | Rescue Women International`,
    description: `${volunteerContent.heroContent} ${volunteerContent.helpMattersSection.content}`,
    keywords:
      "volunteer, domestic abuse support, community outreach, event coordination, support services, administrative support",
    og: {
      title: `Volunteer with Rescue Women International - ${volunteerContent.heroTitle}`,
      description: volunteerContent.helpMattersSection.content,
      image: `${baseUrl}/logo.png`,
      url: volunteerUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: `Volunteer Opportunities - Rescue Women International`,
      description: volunteerContent.volunteerCardsSection.content,
      image: `${baseUrl}/logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "VolunteerAction",
      name: "Volunteer with Rescue Women International",
      description: volunteerContent.helpMattersSection.content,
      agent: {
        "@type": "NGO",
        name: "Rescue Women International",
        url: baseUrl,
      },
      image: `${baseUrl}/logo.png`,
      areaServed: {
        "@type": "Country",
        name: "Ghana", // Replace with actual area served
      },
      potentialAction:
        volunteerContent.volunteerCardsSection.volunteerCards.map((card) => ({
          "@type": "VolunteerAction",
          name: card.title,
          description: card.content,
        })),
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default VolunteerPageMetadata;
