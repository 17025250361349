import React, { lazy } from "react";
import "./about.css";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import AnimatedBackground from "../../components/AnimatedBackground";
import wire from "../../assets/images/wire-5.png";
import { aboutContent } from "../../constants/data";
import AboutUsMetadata from "../../components/generateMetadata/AboutUsMetadata";
const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

const AboutUs = () => {
  return (
    <>
      <AboutUsMetadata />
      <section className="pageHeaderSection pageSection bkgBrown">
        <AnimatedBackground
          backgroundImage={wire}
          className="imageWire pageHeaderWire"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)} className="colorGrey">
                    {aboutContent.heroTitle}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorGrey">
                    {aboutContent.heroContent}
                  </article>
                </motion.div>
              </motion.div>
            </div>

            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={aboutContent.heroImg.lowQualityImage}
                highQualitySrc={aboutContent.heroImg}
                alt={aboutContent.heroTitle}
                className={"fadeImage faded"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="rescueSection pageSection ">
        <div className="container">
          <div className="hor">
            <div className="ver center">
              <div className="imgWrapper">
                <div className="backDrop" />
                <div className="topImg">
                  <ProgressiveImage
                    lowQualitySrc={
                      aboutContent.aboutSection.image.lowQualityImage
                    }
                    highQualitySrc={aboutContent.aboutSection.image}
                    alt={aboutContent.aboutSection.title}
                    className={"secImage"}
                  />
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorBrown">
                    {aboutContent.aboutSection.title}
                  </h2>
                  <h4 className="colorBrown">
                    {aboutContent.aboutSection.subTitle}
                  </h4>
                </div>
                <div className="txt">
                  <article>{aboutContent.aboutSection.content}</article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="missionSection pageSection pb2">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorBrown">
                    {aboutContent.missionSection.title}
                  </h2>
                </div>
                <div className="txt">
                  <article>{aboutContent.missionSection.content}</article>
                </div>
              </div>
            </div>
            <div className="ver center up">
              <div className="imagesWrapper">
                <div className="backImg">
                  <ProgressiveImage
                    lowQualitySrc={
                      aboutContent.missionSection.image1.lowQualityImage
                    }
                    highQualitySrc={aboutContent.missionSection.image1}
                    alt={aboutContent.missionSection.title}
                    className={"secImage tileImg"}
                  />
                </div>
                <div className="overlapImg">
                  <ProgressiveImage
                    lowQualitySrc={
                      aboutContent.missionSection.image2.lowQualityImage
                    }
                    highQualitySrc={aboutContent.missionSection.image2}
                    alt={aboutContent.missionSection.title}
                    className={"secImage tileImg"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="advocateSection moreHeightSection  pageSection bkgYellow">
        <div className="container">
          <div className="hor up">
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorBrown">
                    {aboutContent.advocateSection.title}
                  </h2>
                </div>
                <div className="txt">
                  <article>{aboutContent.advocateSection.content}</article>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imageHolder">
                <ProgressiveImage
                  lowQualitySrc={
                    aboutContent.advocateSection.image1.lowQualityImage
                  }
                  highQualitySrc={aboutContent.advocateSection.image1}
                  alt={aboutContent.advocateSection.title}
                  className={" eventItemImage"}
                />
              </div>
            </div>
            <div className="ver">
              {" "}
              <div className="imageHolder">
                <ProgressiveImage
                  lowQualitySrc={
                    aboutContent.advocateSection.image2.lowQualityImage
                  }
                  highQualitySrc={aboutContent.advocateSection.image2}
                  alt={aboutContent.advocateSection.title}
                  className={"eventItemImage "}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="impactSection pageSection pb2">
        <div className="container">
          <div className="hor columnReversed">
            <div className="ver center up">
              <div className="imagesWrapper">
                <div className="backImg">
                  <ProgressiveImage
                    lowQualitySrc={
                      aboutContent.impactSection.image1.lowQualityImage
                    }
                    highQualitySrc={aboutContent.impactSection.image1}
                    alt={aboutContent.impactSection.title}
                    className={"secImage tileImg"}
                  />
                </div>
                <div className="overlapImg">
                  <ProgressiveImage
                    lowQualitySrc={
                      aboutContent.impactSection.image2.lowQualityImage
                    }
                    highQualitySrc={aboutContent.impactSection.image2}
                    alt={aboutContent.missionSection.title}
                    className={"secImage tileImg"}
                  />
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorBrown">
                    {aboutContent.impactSection.title}
                  </h2>
                </div>
                <div className="txt">
                  <article>{aboutContent.impactSection.content}</article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
