import React from "react";
import { Helmet } from "react-helmet";
import { aboutContent } from "../../constants/data";

const AboutUsMetadata = () => {
  const baseUrl = "https://rescuewomeninternational.org";
  const aboutUsUrl = `${baseUrl}/about-us`;

  const metadata = {
    title: `${aboutContent.aboutSection.title} | About Us`,
    description: aboutContent.aboutSection.content,
    keywords:
      "Rescue Women International, domestic abuse, women empowerment, safety, healing, support",
    og: {
      title: `About ${aboutContent.aboutSection.title} - ${aboutContent.aboutSection.subTitle}`,
      description: aboutContent.missionSection.content,
      image: `${baseUrl}/logo.png`,
      url: aboutUsUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: `${aboutContent.aboutSection.title}: Our Mission and Impact`,
      description: aboutContent.impactSection.content,
      image: `${baseUrl}/logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "NGO",
      name: aboutContent.aboutSection.title,
      url: baseUrl,
      logo: `${baseUrl}/logo.png`,
      description: aboutContent.aboutSection.content,
      foundingDate: "2024", // Replace with actual founding year if known
      address: {
        "@type": "PostalAddress",
        streetAddress: "123 Rescue Street", // Replace with actual address
        addressLocality: "City",
        addressRegion: "State",
        postalCode: "12345",
        addressCountry: "Country",
      },
      sameAs: [
        "https://www.facebook.com/rescuewomeninternational",
        "https://tiktok.com/rescuewomeninternational",
        "https://www.instagram.com/rescuewomeninternational",
        "https://www.youtube.com/@rescuewomeninternational",
      ],
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default AboutUsMetadata;
